export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/(app)/cadastros": [9,[2,3]],
		"/(app)/cadastros/[id]": [10,[2,3,4]],
		"/login": [15,[7]],
		"/logout": [16],
		"/(app)/membros": [11,[2]],
		"/(app)/membros/[uuid]": [12,[2,5]],
		"/(app)/parceiros": [13,[2,6]],
		"/(app)/profissionais": [14,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';